<template>
    <div>
        <!-- Filter start -->
        <b-row>
            <b-col cols="12">
                <b-card>
                    <b-row class="font-weight-bolder">
                        <b-col cols="2">
                            <b-form-group label="WMO ID" label-for="select-wmo" size="sm">
                                <v-select v-model="t_wmoid" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="WMO ID" :options="t_wmoid_list" class="select-size-sm" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="2" class="pad2">
                            <b-form-group label-size="sm" label="Nama Observer" label-for="observer">
                                <v-select v-model="t_observer" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" label="observer" :options="t_observer_opt" :reduce="(item) => item.id" class="select-size-sm" style="margin-top: -2px;" />
                            </b-form-group>
                        </b-col>

                        <b-col cols="2">
                            <b-form-group label="Type" label-for="input-type">
                                <b-form-select id="input-type" size="sm" v-model="metar_speci_type" style="height: 36px;">
                                    <option value="METAR">Metar</option>
                                    <option value="SPECI">Speci</option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="1" class="pad2">
                            <b-form-group label="ICAO" label-for="input-icao">
                                <b-form-input id="input-icao" class="text-uppercase" placeholder="ICAO" size="sm" v-model="icao_id" readonly="readonly" style="height: 36px;" />
                            </b-form-group>
                        </b-col>
                        <b-col cols="2">
                            <b-form-group label="Tanggal" label-for="datepicker">
                                <b-form-datepicker
                                    id="datepicker"
                                    :date-format-options="{
                                        year: 'numeric',
                                        month: 'numeric',
                                        day: 'numeric',
                                    }"
                                    locale="en"
                                    size="sm"
                                    placeholder="Tanggal"
                                    v-model="tanggal"
                                    :disabled="disabled.tanggal"
                                    :max="max"
                                    style="height: 36px;"
                                />
                            </b-form-group>
                        </b-col>
                        <b-col cols="1" class="pad2">
                            <b-form-group label="Jam" label-for="input-jam">
                                <b-form-select id="input-jam" size="sm" v-model="jam" :disabled="disabled.jam" style="height: 36px;">
                                    <option v-for="(option, i) in options.jam" :key="i" :value="option">
                                        {{ option }}
                                    </option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                        <b-col cols="1">
                            <b-form-group label="Menit" label-for="input-menit">
                                <b-form-select id="input-menit" size="sm" v-model="menit" :disabled="disabled.menit" style="height: 36px;">
                                    <option v-for="(option, i) in options.menit" :key="i" :value="option">
                                        {{ option }}
                                    </option>
                                </b-form-select>
                            </b-form-group>
                        </b-col>
                    </b-row>

                    <!-- Filter end -->
                    <hr />
                    <b-tabs align="left">
                        <b-tab title="General">
                            <general ref="general_ref" :general_data.sync="general_data" :isEdit="isEdit" :isFilterComplete="isFilterComplete" :RC_helper="RC_helper" />
                        </b-tab>
                        <b-tab title="Trend">
                            <!-- <b-tab title="Trend" v-bind:disabled="tabDisabled"> -->
                            <trend ref="trend_ref" :trend_data.sync="trend_data" :jam="jam" :menit="menit" :isFilterComplete="isFilterComplete" :RC_helper="RC_helper" />
                        </b-tab>
                    </b-tabs>
                </b-card>

                <b-card>
                    <!-- remark -->
                    <b-row class="my-1">
                        <b-col cols="12">
                            <b-row>
                                <b-col>
                                    <label for="textarea-remark" class="font-weight-bolder"> REMARK </label>
                                </b-col>
                            </b-row>
                            <b-row class="my-1">
                                <b-col sm="12">
                                    <b-form-textarea id="textarea-remark" placeholder="Remark" v-model="remark"></b-form-textarea>
                                </b-col>
                            </b-row>
                        </b-col>
                    </b-row>

                    <hr />

                    <!-- sandi -->
                    <b-row class="my-1">
                        <b-col cols="3">
                            <label for="textarea-sandi" class="font-weight-bolder"> SANDI </label>
                        </b-col>
                        <b-col cols="9">
                            <!-- <b-button class="mr-1 float-right" variant="primary" size="sm" @click="submitForm()" :disabled="!donePreview">
                {{ this.general_data.status.COR ? "Update" : "Send" }}
              </b-button> -->

                            <b-button variant="primary" class="mr-1 float-right" size="sm" :disabled="!donePreview || !roles.isRoleAdd" @click="sendGtsMessage()">
                                Send
                            </b-button>
                            <b-button id="submit_edit" class="mr-1 float-right" variant="primary" size="sm" @click="sumbitCheckQC()" :disabled="!donePreview || !roles.isRoleEdit" v-if="isEdit">
                                {{ this.general_data.status.COR ? "Update" : "Submit" }}
                            </b-button>
                            <b-button id="submit_new" class="mr-1 float-right" variant="primary" size="sm" @click="sumbitCheckQC()" :disabled="!donePreview || !roles.isRoleAdd" v-if="!isEdit">
                                {{ this.general_data.status.COR ? "Update" : "Submit" }}
                            </b-button>
                            <b-button class="mr-1 float-right" variant="warning" size="sm" @click="encodeSandi()"> Preview </b-button>
                            <b-form-checkbox class="mr-1 float-right" v-model="sandiEditable" value="1">
                                Edit Sandi
                            </b-form-checkbox>
                        </b-col>
                    </b-row>

                    <b-row class="my-1">
                        <b-col cols="12">
                            <b-form-textarea id="textarea-sandi" placeholder="Sandi" v-model="sandi_with_header" :disabled="sandiEditable == 0" class="textsandi"></b-form-textarea>
                        </b-col>
                    </b-row>
                </b-card>
            </b-col>
        </b-row>
        <b-modal id="send-gts-message" ref="send-gts-message" title="SEND GTS MESSAGES" button-size="md" size="lg" centered no-close-on-backdrop>
            <b-row class="mb-1">
                <b-col cols="12">
                    <b-form-textarea id="textarea-sandi-gts" placeholder="Sandi" v-model="sandi_gts_message" class="font-weight-bolder textsandi" rows="9"> </b-form-textarea>
                </b-col>
            </b-row>
            <template #modal-footer>
                <b-button size="sm" @click="onCancel">
                    Cancel
                </b-button>
                <b-button size="sm" variant="gradient-primary" @click="onSendBmkgSwitching">
                    Send to Gts Switching
                </b-button>
            </template>
        </b-modal>
        <b-overlay :show="showLoading" rounded="sm" no-wrap></b-overlay>
    </div>
</template>

<script>
import axios from "axios";
import { BTabs, BTab, BRow, BCol, BTable, BCardText, BFormInput, BContainer, BButton, BCard, BFormTimepicker, BFormDatepicker, BFormTextarea, BIcon, BFormGroup, BFormSelect, BOverlay, BFormCheckbox, BModal } from "bootstrap-vue";
import General from "./metarspecicomponent/general.vue";
import Trend from "./metarspecicomponent/trend.vue";
import Helper from "@/api/helper";
import metadata from "@/api/metadata";
import error_handler from "@/api/error_handler";
import ToastificationContent from "@core/components/toastification/ToastificationContent.vue";
import vSelect from "vue-select";
import Params from "@/attr/metadata/params";
import RC_helper from "@/validators/RC_helper";
import qc_helper from "@/api/qc_helper";
import {
    GC_WIND_DIRECTION,
    GC_WIND_SPEED,
    GC_WIND_GUST,
    GC_VISIBILITY_PREVAILING,
    GC_VISIBILITY_MINIMUM,
    GC_VERTICAL_VIS,
    GC_AIR_TEMP,
    GC_DEW_POINT,
    GC_PREASSURE,
    GC_SEA_SURFACE_TEMP,
    GC_CLOUD_HEIGHT,
    RC_METAR,
    validationPerN,
} from "@/validators/metarvalidator";
export default {
    components: {
        BTabs,
        BTab,
        General,
        Trend,
        BRow,
        BCol,
        BTable,
        BCardText,
        BFormInput,
        BContainer,
        BButton,
        BCard,
        BFormTimepicker,
        BFormDatepicker,
        BFormTextarea,
        BIcon,
        BFormGroup,
        BFormSelect,
        ToastificationContent,
        vSelect,
        BOverlay,
        BFormCheckbox,
        BModal,
    },

    props: ["roles"],

    data() {
        const now = new Date();
        const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const maxDate = new Date(today);

        return {
            RC_m_cloud_new: {},
            last_qc_histories: null,
            RC_helper: RC_helper,
            max: maxDate,
            donePreview: false,
            isEdit: false,
            showLoading: false,
            t_station: "",
            t_station_list: [],
            t_wmoid: "",
            t_wmoid_list: [],
            t_path: "",
            t_observer: "",
            t_observer_opt: [],
            icao_id: "",
            metar_speci_type: "METAR",
            tanggal: "",
            jam: "",
            menit: "",
            remark: "",
            sandi: "",
            sandi_with_header: "",
            sandi_gts_message: "",
            tabDisabled: false,
            options: {
                jam: ["00", "01", "02", "03", "04", "05", "06", "07", "08", "09", "10", "11", "12", "13", "14", "15", "16", "17", "18", "19", "20", "21", "22", "23"],
                menit: ["00", "30"],
                menit_hourly: ["00", "30"],
            },
            general_data: {
                status: {
                    COR: false,
                    NIL: false,
                    AUTO: false,
                    CAVOK: false,
                },
                visibility: {
                    prevailing: "",
                    NDV: false,
                    minimum: "",
                    min_vis: "",
                },
                weatherGroup: 0,
                weathers: {
                    // 0-2 present weather
                    [0]: "",
                    [1]: "",
                    [2]: "",
                },
                recent_weathers: ["", "", ""],
                wind_shear: {
                    ws_all_runaway: false,
                    runway: "",
                    items: [
                        // { runway: "R24" },
                    ],
                },
                winds: {
                    VRB: false,
                    direction: "",
                    speed: "",
                    gust: "",
                    wd_dn: "",
                    wd_dx: "",
                },
                clouds: {
                    CLEAR: "",
                    jumlah: "",
                    tinggi: "",
                    type: "",
                    vertical_vis: "",
                    items: [
                        // { jumlah: "FEW", tinggi: "12", type: "CB" },
                    ],
                },
                air: {
                    air_temp: "",
                    dew_point: "",
                    pressure: "",
                    sea_surface_temp: "",
                    sea_state: "",
                },
                rvr: {
                    runway: "",
                    vrvrvrvr: "",
                    vr_vis_min: "",
                    vr_vis_max: "",
                    ins_limit: "",
                    tendency: "",
                    items: [
                        // {
                        // 	runway: "2",
                        // 	vrvrvrvr: "2",
                        // 	vr_vis_min: "2",
                        // 	vr_vis_max: "2",
                        // 	ins_limit:"2",
                        // 	tendency: "2",
                        // },
                    ],
                },
                state: {
                    runway: "",
                    state: "",
                    contamination: "",
                    depth: "",
                    braking_coefficient: "",
                    items: [
                        // {
                        // 	runway: "2",
                        // 	state: "2",
                        // 	contamination: "2",
                        // 	depth: "2",
                        // 	braking_coefficient: "2",
                        // },
                    ],
                },
            },
            trend_data: {
                trend: "",
                time_group: {
                    indicator_1: "",
                    indicator_2: "",
                    time_1: "",
                    time_2: "",
                },
                winds: {
                    direction: "",
                    speed: "",
                    gust: "",
                },
                status: {
                    CAVOK: false,
                },
                prevailing: "",
                weather: {
                    NSW: false,
                    NSC: false,
                },
                forecast_weather: "",
                clouds: {
                    jumlah: "",
                    tinggi: "",
                    type: "",
                    vertical_vis: "",
                    items: [
                        // { jumlah: "FEW (1-2 oktas)", tinggi: "12", type: "CB" },
                    ],
                },
            },
            sandiEditable: 0,
            is_metarspeci_send_to_bmkgswitching: 0,
            metarspeci_gts_metar_header: "",
            metarspeci_gts_speci_header: "",
        };
    },
    computed: {
        t_data_id() {
            return this.t_station.station_id + "_metarspeci_" + this.tanggal + "T" + this.jam + this.menit;
        },
        isFilterComplete() {
            // console.log('isFilterComplete', this.icao_id)
            return this.t_station != "" && this.t_observer != "" && this.icao_id != "" && this.icao_id.length == 4;
        },
        disabled() {
            return {
                tanggal: !this.isEdit && this.general_data.status.COR,
                jam: !this.isEdit && this.general_data.status.COR,
                menit: !this.isEdit && this.general_data.status.COR,
            };
        },
    },
    mounted() {
        this.listStasiun();
    },
    methods: {
        async setQCData() {
            this.metarspeci_gts_metar_header = "";
            this.metarspeci_gts_speci_header = "";
            this.metarspeci_send_to_bmkgswitching = 0;
            // const qc_data = await metadata.getQCData(this.t_station.path, Params.GET_QC_STATION_KEY("metar"));
            const mstation = await metadata.getStationByPath(this.t_station.path);
            const qc_data = mstation.data[Params.GET_QC_STATION_KEY("metar")];
            // console.log("qc data : ", mstation, qc_data);
            const qc_params = Params.GET_PARAMS("metar");
            const qc_key = Params.GET_KEY("metar");
            this.RC_helper.setData(qc_data, qc_params, qc_key);

            const gts_header = mstation.data["BmkgSatu.metadata.gts.IGTS"];
            gts_header["metarspeci_send_to_bmkgswitching"];
            this.metarspeci_gts_metar_header = gts_header["metarspeci_gts_metar_header"];
            this.metarspeci_gts_speci_header = gts_header["metarspeci_gts_speci_header"];
            this.is_metarspeci_send_to_bmkgswitching = gts_header["metarspeci_send_to_bmkgswitching"];
            // console.log('gts_header', this.metarspeci_gts_metar_header, this.metarspeci_gts_speci_header)
        },
        async getOprHours() {
            const data = await metadata.getOprHours(this.t_station.path);
            const opn = data["BmkgSatu.metadata.bmkgentry.IBMKGEntry"];
            let hourly = opn["is_metar_hourly"];
            let half = opn["is_metar_half_hourly"];
            // console.log("hourly : ", hourly);
            // console.log("half : ", half);
            if (hourly == true) {
                this.options.menit_hourly = ["00"];
                this.options.menit = ["00"];
            } else {
                this.options.menit_hourly = ["00", "30"];
                this.options.menit = ["00", "30"];
            }
        },
        async listStasiun() {
            this.showLoading = true;
            try {
                const { data } = await metadata.getAllStasiunWithWIcao();
                this.t_station_list = [];
                this.t_wmoid_list = [];
                for (let i = 0; i < data.length; i++) {
                    if (this.t_wmoid_list[i]) continue;
                    let wmoid = data[i].station_wmo_id;
                    this.t_wmoid_list.push(wmoid);
                    this.t_station_list[wmoid] = {
                        station_id: data[i].station_id,
                        path: data[i].path,
                        station_name: data[i].station_name,
                        icao: data[i].station_icao,
                    };
                }
                this.showLoading = false;
            } catch (e) {
                console.log(e);
                this.showLoading = false;
            }
        },
        async listObserver() {
            this.showLoading = true;
            this.t_observer_opt = await metadata.getObserverStation(this.t_station.station_id);
            this.showLoading = false;
        },
        fetchData() {
            if (!this.isEdit && this.general_data.status.COR) {
            } else if (this.t_station == "") {
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Pilih stasiun terlebih dahulu",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            } else if (this.jam != "" && this.tanggal != "" && this.menit != "") {
                this.cekExistData();
            } else {
                this.resetForm();
                this.isEdit = false;
            }
        },
        resetForm() {
            // console.log('resetForm')
            this.last_qc_histories = null;

            this.general_data = {
                ...this.general_data,
                status: {
                    COR: false,
                    NIL: false,
                    AUTO: false,
                    CAVOK: false,
                },
                visibility: {
                    prevailing: "",
                    NDV: false,
                    minimum: "",
                    min_vis: "",
                },
                weatherGroup: 0,
                weathers: {
                    // 0-2 present weather
                    [0]: "",
                    [1]: "",
                    [2]: "",
                },
                RC: {
                    m_wind_speed: { status: false, message: "" },
                    m_wind_gust: { status: false, message: "" },
                    m_cloud_height: { status: false, message: "" },
                    m_air_temp: { status: false, message: "" },
                    m_air_pressure: { status: false, message: "" },
                },
                weathers_modal: [
                    // 0-2 present weather
                    {
                        group_1: "",
                        group_2: "",
                        group_3: "",
                        group_4: "",
                        group_5: "",
                        none: "",
                    },
                    {
                        group_1: "",
                        group_2: "",
                        group_3: "",
                        group_4: "",
                        group_5: "",
                        none: "",
                    },
                    {
                        group_1: "",
                        group_2: "",
                        group_3: "",
                        group_4: "",
                        group_5: "",
                        none: "",
                    },
                ],
                recent_weathers: ["", "", ""],
                wind_shear: {
                    ws_all_runaway: false,
                    runway: "",
                    items: [
                        // { runway: "R24" },
                    ],
                },
                winds: {
                    VRB: false,
                    direction: "",
                    speed: "",
                    gust: "",
                    wd_dn: "",
                    wd_dx: "",
                },
                clouds: {
                    CLEAR: "",
                    jumlah: "",
                    tinggi: "",
                    type: "",
                    vertical_vis: "",
                    items: [
                        // { jumlah: "FEW", tinggi: "12", type: "CB" },
                    ],
                },
                air: {
                    air_temp: "",
                    dew_point: "",
                    pressure: "",
                    sea_surface_temp: "",
                    sea_state: "",
                },
                rvr: {
                    runway: "",
                    designator: "",
                    vrvrvrvr: "",
                    vr_vis_min: "",
                    vr_vis_max: "",
                    ins_limit: "",
                    tendency: "",
                    items: [
                        // {
                        // 	runway: "2",
                        // 	vrvrvrvr: "2",
                        // 	vr_vis_min: "2",
                        // 	vr_vis_max: "2",
                        // 	ins_limit:"2",
                        // 	tendency: "2",
                        // },
                    ],
                },
                state: {
                    runway: "",
                    state: "",
                    contamination: "",
                    depth: "",
                    braking_coefficient: "",
                    items: [
                        // {
                        // 	runway: "2",
                        // 	state: "2",
                        // 	contamination: "2",
                        // 	depth: "2",
                        // 	braking_coefficient: "2",
                        // },
                    ],
                },
            };
            this.$refs["general_ref"].general_data = this.general_data;

            this.trend_data = {
                ...this.trend_data,
                trend: "",
                time_group: {
                    indicator_1: "",
                    indicator_2: "",
                    time_1: "",
                    time_2: "",
                },
                winds: {
                    direction: "",
                    speed: "",
                    gust: "",
                },
                status: {
                    CAVOK: false,
                },
                prevailing: "",
                weather: {
                    NSW: false,
                    NSC: false,
                },
                forecast_weather: "",
                forecast_modal: {
                    group_1: "",
                    group_2: "",
                    group_3: "",
                    group_4: "",
                    group_5: "",
                    none: "",
                },
                clouds: {
                    jumlah: "",
                    tinggi: "",
                    type: "",
                    vertical_vis: "",
                    items: [
                        // { jumlah: "FEW (1-2 oktas)", tinggi: "12", type: "CB" },
                    ],
                },
            };
            this.$refs["trend_ref"].trend_data = this.trend_data;

            // this.metar_speci_type = "METAR";
            this.remark = "";
            this.sandi = "";
            this.sandi_with_header = "";

            this.$refs["general_ref"].$v.$reset();
            this.$refs["trend_ref"].$v.$reset();
        },
        validateForm() {
            let valid = true;
            this.$refs["general_ref"].$v.$touch();
            if (this.$refs["general_ref"].$v.$invalid) {
                valid = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Data General tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            }
            if (this.metar_speci_type === "METAR") {
                this.$refs["trend_ref"].$v.$touch();
                if (this.$refs["trend_ref"].$v.$invalid) {
                    valid = false;
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Data Trend tidak valid. Periksa kembali inputan anda",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                }
            }
            if (!this.isFilterComplete) {
                valid = false;
                this.$toast({
                    component: ToastificationContent,
                    props: {
                        title: "Data Filter tidak valid. Periksa kembali inputan anda",
                        icon: "BellIcon",
                        variant: "danger",
                    },
                });
            }

            return valid;
        },
        convertArrToObj(arr) {
            const res = {};
            arr = [...arr];
            for (let i = 0; i < arr.length; i++) {
                res[i + 1] = { ...arr[i] };
            }
            return res;
        },
        isValidID(id) {
            let valid = /^[0-9]+_[a-zA-Z]+_[0-9]{4}-[0-9]{2}-[0-9]{2}T[0-9]+$/.test(id);
            if (!valid) {
                this.$swal({
                    title: "ID Error!",
                    text: "Data stasiun/wmo, tanggal, atau jam tidak valid, silahkan periksa input data",
                    icon: "error",
                    customClass: {
                        confirmButton: "btn btn-primary",
                    },
                    buttonsStyling: false,
                });
            }
            return valid;
        },
        sumbitCheckQC() {
            const RC = {
                ...this.$refs["general_ref"].RC,
                ...this.$refs["trend_ref"].T_RC,
            };
            let qc_found = Object.entries(RC).filter((e) => e[1].status);
            const m_cloud_qc_l = this.convertArrToObj(
                this.general_data.clouds.items.map((item) => ({
                    amount_nsnsns: item.jumlah,
                    height_hshshs: item.tinggi,
                    type: item.type,
                }))
            );
            // console.log("m_cloud_qc_l : ", m_cloud_qc_l);
            let m_cloud_qc = null;
            var i = 1;

            this.RC_m_cloud_new = {
                m_cloud_height_hshshs_1: { status: false, message: "", value: "" },
                m_cloud_height_hshshs_2: { status: false, message: "", value: "" },
                m_cloud_height_hshshs_3: { status: false, message: "", value: "" },
                m_cloud_height_hshshs_4: { status: false, message: "", value: "" },
                m_cloud_height_hshshs_5: { status: false, message: "", value: "" },
                m_cloud_height_hshshs_6: { status: false, message: "", value: "" },
            };
            for (let mc in m_cloud_qc_l) {
                let value = m_cloud_qc_l[mc].height_hshshs;
                let gc = false;
                gc = GC_CLOUD_HEIGHT(value);
                let raw = { status: true, message: "" };
                raw = this.RC_helper.check(value, "m_cloud_height_hshshs");

                this.RC_m_cloud_new["m_cloud_height_hshshs_" + i].status = gc && !raw.status;
                this.RC_m_cloud_new["m_cloud_height_hshshs_" + i].message = raw.message;
                this.RC_m_cloud_new["m_cloud_height_hshshs_" + i].value = value;
                i++;
            }
            // console.log("Qc found : ", qc_found);
            // console.log("Qc len : ", qc_found.length);
            if (qc_found.length > 0) {
                this.$swal({
                    title: "Apakah Anda Yakin?",
                    text: "Ada Data Terkena Range Check ",
                    icon: "warning",
                    showCancelButton: true,
                    confirmButtonText: "Submit",
                    cancelButtonText: "Cek Data",
                    allowOutsideClick: false,
                    allowEscapeKey: false,
                    customClass: {
                        confirmButton: "btn btn-primary",
                        cancelButton: "btn btn-outline-danger ml-1",
                    },
                    buttonsStyling: false,
                }).then((result) => {
                    if (result.value) {
                        this.submitForm();
                    } else if (result.dismiss === "cancel") {
                        return;
                    }
                });
            } else {
                this.submitForm();
            }
        },
        submitForm() {
            if (!this.validateForm()) return false;
            if (!this.isValidID(this.t_data_id)) return false;
            // this.showLoading = true;

            // qc parameter:START
            let qc_flag = 0;
            let qc_histories = {
                before: this.last_qc_histories,
                after: null,
            };
            // range check
            // RC: { status, message }
            const RC = {
                ...this.$refs["general_ref"].RC,
                ...this.RC_m_cloud_new,
                ...this.$refs["trend_ref"].T_RC,
            };
            let qc_found = Object.entries(RC)
                .filter((e) => e[1].status)
                // .map((e) => e[0])
                .map((data) => {
                    // RC NGIKUTIN YANG LAIN AJA... agak kacau disini
                    let e = data[0];
                    let value = data[1].value;
                    let param_check = "";
                    // console.log("params : ",e);
                    switch (e) {
                        case "m_wind_speed":
                        case "t_wind_speed":
                            param_check = "m_wind_speed_ff";
                            break;
                        case "m_wind_gust":
                        case "t_wind_gust":
                            param_check = "m_wind_gust_fmfm";
                            break;
                        case "m_cloud_height":
                        case "m_cloud_height_hshshs_1":
                        case "m_cloud_height_hshshs_2":
                        case "m_cloud_height_hshshs_3":
                        case "m_cloud_height_hshshs_4":
                        case "m_cloud_height_hshshs_5":
                        case "m_cloud_height_hshshs_6":
                        case "t_cloud_height":
                            param_check = "m_cloud_height_hshshs";
                            break;
                        case "m_air_temp":
                            param_check = "m_air_temperature_tt";
                            break;
                        case "m_air_pressure":
                            param_check = "m_qnh_pressure_phphph";
                            break;
                        default:
                            break;
                    }
                    const roles_data = RC_helper.data[param_check];
                    // console.log(roles_data);
                    const roles = Object.values(roles_data).join(",");
                    return {
                        raw_type: "MetarSpeci",
                        atribut: e,
                        value: value,
                        roles: roles,
                        type: "range_check",
                        message: `batas bawah ${roles_data.min}, batas atas ${roles_data.max}, special value ${roles_data.values}`,
                    };
                });
            if (Object.values(qc_found).length) {
                qc_flag = 1;
                qc_histories.after = qc_found;
            }
            if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
                qc_flag = 2;
            }
            // console.log("RC: ", RC);
            // console.log("qc_found ok: ", qc_found);
            // return;
            // qc parameter:END

            // sesuain bentuk array
            const m_cloud = this.convertArrToObj(
                this.general_data.clouds.items.map((item) => ({
                    amount_nsnsns: item.jumlah,
                    height_hshshs: item.tinggi,
                    type: item.type,
                }))
            );
            const m_rvr = this.convertArrToObj(
                this.general_data.rvr.items.map((item) => ({
                    designator_drdr: item.runway,
                    vrvrvrvr: item.vrvrvrvr,
                    variation_vrvrvrvr_min: item.vr_vis_min,
                    variation_vrvrvrvr_max: item.vr_vis_max,
                    instrument_limit: item.ins_limit,
                    tendency_i: item.tendency,
                }))
            );

            const m_wind_shear = this.convertArrToObj(
                this.general_data.wind_shear.items.map((item) => ({
                    runway_ind_drdr: item.runway,
                }))
            );

            const m_runway = this.convertArrToObj(
                this.general_data.state.items.map((item) => ({
                    state_ind_drdr: item.runway,
                    depos_state_er: item.state,
                    depos_cr: item.contamination,
                    depos_depth_erer: item.depth,
                    depos_braking_brbr: item.braking_coefficient,
                }))
            );

            const m_trend_cloud = this.convertArrToObj(
                this.trend_data.clouds.items.map((item) => ({
                    nsnsns: item.jumlah,
                    height_hshshs: item.tinggi,
                    type: item.type,
                }))
            );

            const data_timestamp = this.tanggal + "T" + this.jam + this.menit;

            let post_data_trend = {};
            if (this.metar_speci_type === "METAR") {
                post_data_trend = {
                    m_trend: this.trend_data.trend,
                    m_trend_tt_1: this.trend_data.time_group.indicator_1,
                    m_trend_tt_2: this.trend_data.time_group.indicator_2,
                    m_trend_gggg_1: this.trend_data.time_group.time_1 !== "" ? this.trend_data.time_group.time_1 : null,
                    m_trend_gggg_2: this.trend_data.time_group.time_2 !== "" ? this.trend_data.time_group.time_2 : null,
                    m_trend_wind_dir_dd: this.trend_data.winds.direction !== "" ? this.trend_data.winds.direction : null,
                    m_trend_wind_speed_ff: this.trend_data.winds.speed !== "" ? this.trend_data.winds.speed : null,
                    m_trend_wind_gust_fmfm: this.trend_data.winds.gust !== "" ? this.trend_data.winds.gust : null,
                    m_trend_cavok: this.trend_data.status.CAVOK,
                    m_trend_vvvv: this.trend_data.prevailing !== "" ? this.trend_data.prevailing : null,
                    m_trend_nsw: this.trend_data.weather.NSW,
                    m_trend_ww: this.trend_data.forecast_weather,
                    m_trend_nsc: this.trend_data.weather.NSC,
                    m_trend_cloud: m_trend_cloud,
                    m_trend_vv_vvhshshs: this.trend_data.clouds.vertical_vis !== "" ? this.trend_data.clouds.vertical_vis : null,
                };
            }

            const post_data = {
                "@type": "MetarSpeci",
                id: this.t_data_id,
                m_cor: this.general_data.status.COR,
                m_nil: this.general_data.status.NIL,
                m_auto: this.general_data.status.AUTO,
                m_wind_dir_ddd: this.general_data.winds.direction !== "" ? this.general_data.winds.direction : null,
                m_wind_speed_ff: this.general_data.winds.speed !== "" ? this.general_data.winds.speed : null,
                m_wind_gust_fmfm: this.general_data.winds.gust !== "" ? this.general_data.winds.gust : null,
                m_dxdxdx: this.general_data.winds.wd_dx !== "" ? this.general_data.winds.wd_dx : null,
                m_dndndn: this.general_data.winds.wd_dn !== "" ? this.general_data.winds.wd_dn : null,
                m_vrb: this.general_data.winds.VRB,
                m_cavok: this.general_data.status.CAVOK,
                m_prevailing_visibility_vvvv: this.general_data.visibility.prevailing !== "" ? this.general_data.visibility.prevailing : null,
                m_visibiility_min_vnvnvnvn: this.general_data.visibility.minimum !== "" ? this.general_data.visibility.minimum : null,
                m_visibility_min_direction_dv: this.general_data.visibility.min_vis,
                m_ndv: this.general_data.visibility.NDV,
                m_rvr: m_rvr,
                m_present_weather_ww_1: this.general_data.weathers[0],
                m_present_weather_ww_2: this.general_data.weathers[1],
                m_present_weather_ww_3: this.general_data.weathers[2],
                m_recent_weather_ww_1: this.general_data.recent_weathers[0],
                m_recent_weather_ww_2: this.general_data.recent_weathers[1],
                m_recent_weather_ww_3: this.general_data.recent_weathers[2],
                m_cloud_clear: this.general_data.clouds.CLEAR,
                m_cloud: m_cloud,
                m_vertical_visibility_vvhshshs: this.general_data.clouds.vertical_vis,
                m_air_temperature_tt: this.general_data.air.air_temp !== "" ? this.general_data.air.air_temp : null,
                m_dew_point_temp_tdtd: this.general_data.air.dew_point !== "" ? this.general_data.air.dew_point : null,
                m_qnh_pressure_phphph: this.general_data.air.pressure !== "" ? this.general_data.air.pressure : null,
                m_sea_surface_temperature_tsts: this.general_data.air.sea_surface_temp !== "" ? this.general_data.air.sea_surface_temp : null,
                m_sea_state_s: this.general_data.air.sea_state !== "" ? this.general_data.air.sea_state : null,
                m_wind_shear_all_runway: this.general_data.wind_shear.ws_all_runaway,
                m_wind_shear: m_wind_shear,
                m_runway: m_runway,
                ...post_data_trend,
                m_remark: this.remark,
                encoded_metar_speci: this.sandi,
                encoded_metar_speci_with_header: this.sandi_with_header,
                source: "text",
                icao_id: this.icao_id.toUpperCase(),
                type: this.metar_speci_type,
                observer_id: this.t_observer,
                observer_name: this.t_observer_opt.find((el) => el.id === this.t_observer).observer,
                data_timestamp: data_timestamp,
                qc_flag: qc_flag,
                qc_histories: JSON.stringify(qc_histories),
            };

            if (this.isEdit) {
                // melakukan update data lama tanpa COR
                const post_url = Helper.getBaseUrl() + this.t_path + "/" + this.t_data_id;
                axios
                    .patch(post_url, post_data, Helper.getConfig())
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.$swal({
                                title: "Edit data berhasil!",
                                text: "Edit Data Metar/Speci",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                            this.showLoading = false;
                            this.tanggal = "";
                            this.jam = "";
                            this.menit = "";
                            this.donePreview = false;
                            this.isEdit = false;
                            this.resetForm();
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        error_handler(this, error, "Metar/Speci", "Edit Data Error");
                    });
                // console.log("update QC Notif : ", this.t_path);
                var arr_path = this.t_path.split("/");
                var reg_id = arr_path[1];
                qc_helper.editQCNotification(
                    this.t_data_id, // id entry
                    "MetarSpeci", // type form entry
                    this.tanggal, // tanggal entry
                    `${this.jam}:${this.menit}`, // jam entry
                    JSON.stringify(qc_histories), // qc histories
                    qc_flag,
                    this.t_station.station_id,
                    this.t_station.station_name,
                    this.t_observer,
                    this.t_observer_opt.find((el) => el.id === `${this.t_observer}`).observer, // observer name
                    reg_id,
                    this.t_path + "/" + this.t_data_id
                );
            } else if (this.general_data.status.COR) {
                // melakukan update
                const post_url = Helper.getBaseUrl() + this.t_path + "/" + this.t_data_id;
                axios
                    .patch(post_url, post_data, Helper.getConfig())
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.$swal({
                                title: "Edit data berhasil!",
                                text: "Edit Data Metar/Speci",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                            this.showLoading = false;
                            this.tanggal = "";
                            this.jam = "";
                            this.menit = "";
                            this.donePreview = false;
                            this.resetForm();
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        error_handler(this, error, "Metar/Speci", "Edit Data Error");
                    });

                // update qc notification
                // console.log("update QC Notif : ", this.t_path);
                var arr_path = this.t_path.split("/");
                var reg_id = arr_path[1];
                qc_helper.editQCNotification(
                    this.t_data_id, // id entry
                    "MetarSpeci", // type form entry
                    this.tanggal, // tanggal entry
                    `${this.jam}:${this.menit}`, // jam entry
                    JSON.stringify(qc_histories), // qc histories
                    qc_flag,
                    this.t_station.station_id,
                    this.t_station.station_name,
                    this.t_observer,
                    this.t_observer_opt.find((el) => el.id === `${this.t_observer}`).observer, // observer name
                    reg_id,
                    this.t_path + "/" + this.t_data_id
                );
                if (this.$route.query.qc_edit == true || this.$route.query.qc_edit == "true") {
                    this.$router.replace({ query: null });
                }
            } else {
                // post data
                const post_url = Helper.getBaseUrl() + this.t_path;
                axios
                    .post(post_url, post_data, Helper.getConfig())
                    .then((response) => {
                        if (response.status == 201 || response.status == 200 || response.status == 204) {
                            this.$swal({
                                title: "Input Data Berhasil!",
                                text: "Input Data Metar/Speci",
                                icon: "success",
                                customClass: {
                                    confirmButton: "btn btn-primary",
                                },
                                buttonsStyling: false,
                            });
                            this.showLoading = false;
                            this.tanggal = "";
                            this.jam = "";
                            this.menit = "";
                            this.donePreview = false;
                            this.resetForm();
                        }
                    })
                    .catch((error) => {
                        this.showLoading = false;
                        error_handler(this, error, "Metar/Speci", "Input Data Error");
                    });

                // input qc notification
                if (qc_flag == 1) {
                    var arr_path = this.t_path.split("/");
                    var reg_id = arr_path[1];
                    qc_helper.editQCNotification(
                        this.t_data_id, // id entry
                        "MetarSpeci", // type form entry
                        this.tanggal, // tanggal entry
                        `${this.jam}:${this.menit}`, // jam entry
                        JSON.stringify(qc_histories), // qc histories
                        qc_flag,
                        this.t_station.station_id,
                        this.t_station.station_name,
                        this.t_observer,
                        this.t_observer_opt.find((el) => el.id === `${this.t_observer}`).observer, // observer name
                        reg_id,
                        this.t_path + "/" + this.t_data_id
                    );
                }
            }
        },
        async cekExistData() {
            this.showLoading = true;
            const get_url = Helper.getBaseUrl() + this.t_path + "/" + this.t_data_id;
            axios
                .get(get_url, Helper.getConfig())
                .then((response) => {
                    this.$swal({
                        title: "Data Exist",
                        text: "Data tanggal tersebut sudah ada. View data ?",
                        icon: "warning",
                        showCancelButton: true,
                        confirmButtonText: "View",
                        allowOutsideClick: false,
                        allowEscapeKey: false,
                        customClass: {
                            confirmButton: "btn btn-primary",
                            cancelButton: "btn btn-outline-danger ml-1",
                        },
                        buttonsStyling: false,
                    }).then((result) => {
                        if (result.value) {
                            const { data } = response;
                            this.isEdit = true;
                            this.popupData(data);
                            this.showLoading = false;
                            this.donePreview = false;
                        } else if (result.dismiss === "cancel") {
                            this.tanggal = "";
                            this.jam = "";
                            this.menit = "";
                            this.donePreview = false;
                            this.resetForm();
                            this.isEdit = false;
                            this.showLoading = false;
                        }
                    });
                })
                .catch((error) => {
                    const { response } = error;
                    if (response.status === 404) {
                        // input baru
                    } else {
                        console.log(error);
                    }
                    this.resetForm();
                    this.isEdit = false;
                    this.donePreview = false;
                    this.showLoading = false;
                });
        },
        popupData(data) {
            if (this.t_data?.qc_histories) {
                this.last_qc_histories = JSON.parse(this.t_data.qc_histories)?.after || null;
            } else {
                this.last_qc_histories = null;
            }

            // convert objects to arrays
            const wind_shear = Object.entries(data.m_wind_shear).map(([key, item]) => ({
                runway: item.runway_ind_drdr,
            }));

            const clouds = Object.entries(data.m_cloud).map(([key, item]) => ({
                jumlah: item.amount_nsnsns,
                tinggi: item.height_hshshs,
                type: item.type,
            }));

            const rvr = Object.entries(data.m_rvr).map(([key, item]) => ({
                runway: item.designator_drdr,
                vrvrvrvr: item.vrvrvrvr,
                vr_vis_min: item.variation_vrvrvrvr_min,
                vr_vis_max: item.variation_vrvrvrvr_max,
                ins_limit: item.instrument_limit,
                tendency: item.tendency_i,
            }));

            const state = Object.entries(data.m_runway).map(([key, item]) => ({
                runway: item.state_ind_drdr,
                state: item.depos_state_er,
                contamination: item.depos_cr,
                depth: item.depos_depth_erer,
                braking_coefficient: item.depos_braking_brbr,
            }));

            this.general_data.status.COR = data.m_cor;
            this.general_data.status.NIL = data.m_nil;
            this.general_data.status.AUTO = data.m_auto;
            this.general_data.winds.direction = data.m_wind_dir_ddd;
            this.general_data.winds.speed = data.m_wind_speed_ff;
            this.general_data.winds.gust = data.m_wind_gust_fmfm;
            this.general_data.winds.wd_dx = data.m_dxdxdx;
            this.general_data.winds.wd_dn = data.m_dndndn;
            this.general_data.winds.VRB = data.m_vrb;
            this.general_data.status.CAVOK = data.m_cavok;
            this.general_data.visibility.prevailing = data.m_prevailing_visibility_vvvv;
            this.general_data.visibility.minimum = data.m_visibiility_min_vnvnvnvn;
            this.general_data.visibility.min_vis = data.m_visibility_min_direction_dv;
            this.general_data.visibility.NDV = data.m_ndv;
            this.general_data.rvr.items = rvr;
            this.general_data.weathers[0] = data.m_present_weather_ww_1;
            this.general_data.weathers[1] = data.m_present_weather_ww_2;
            this.general_data.weathers[2] = data.m_present_weather_ww_3;
            this.general_data.recent_weathers[0] = data.m_recent_weather_ww_1;
            this.general_data.recent_weathers[1] = data.m_recent_weather_ww_2;
            this.general_data.recent_weathers[2] = data.m_recent_weather_ww_3;
            this.general_data.clouds.CLEAR = data.m_cloud_clear;
            this.general_data.clouds.items = clouds;
            this.general_data.clouds.vertical_vis = data.m_vertical_visibility_vvhshshs;
            this.general_data.air.air_temp = data.m_air_temperature_tt;
            this.general_data.air.dew_point = data.m_dew_point_temp_tdtd;
            this.general_data.air.pressure = data.m_qnh_pressure_phphph;
            this.general_data.air.sea_surface_temp = data.m_sea_surface_temperature_tsts;
            this.general_data.air.sea_state = data.m_sea_state_s;
            this.general_data.wind_shear.ws_all_runaway = data.m_wind_shear_all_runway;
            this.general_data.wind_shear.items = wind_shear;
            this.general_data.state.items = state;
            this.$refs["general_ref"].general_data = this.general_data;

            if (data.type === "METAR") {
                this.trend_data.trend = data.m_trend;
                this.trend_data.time_group.indicator_1 = data.m_trend_tt_1;
                this.trend_data.time_group.indicator_2 = data.m_trend_tt_2;
                this.trend_data.time_group.time_1 = data.m_trend_gggg_1;
                this.trend_data.time_group.time_2 = data.m_trend_gggg_2;
                this.trend_data.winds.direction = data.m_trend_wind_dir_dd;
                this.trend_data.winds.speed = data.m_trend_wind_speed_ff;
                this.trend_data.winds.gust = data.m_trend_wind_gust_fmfm;
                this.trend_data.status.CAVOK = data.m_trend_cavok;
                this.trend_data.prevailing = data.m_trend_vvvv;
                this.trend_data.weather.NSW = data.m_trend_nsw;
                this.trend_data.forecast_weather = data.m_trend_ww;
                this.trend_data.weather.NSC = data.m_trend_nsc;
                this.trend_data.clouds.vertical_vis = data.m_trend_vv_vvhshshs;
                this.$refs["trend_ref"].trend_data = this.trend_data;
            }

            this.remark = data.m_remark;
            this.sandi = data.encoded_metar_speci;
            this.sandi_with_header = data.encoded_metar_speci_with_header;
            this.icao_id = data.icao_id ? data.icao_id : this.icao_id;
            // this.metar_speci_type = data.type;
            // let observer_valid = this.t_observer_opt.find((el) => el.id === `${data.observer_id}`);

            // if (observer_valid) {
            //   this.t_observer = `${data.observer_id}`;
            // } else {
            //   this.t_observer = null;
            // }
            let observer_valid = this.t_observer_opt.find((el) => el.id === data.observer_id);
            if (observer_valid) {
                this.t_observer = data.observer_id;
            } else {
                this.t_observer = null;
            }
        },
        async getCorData() {
            this.showLoading = true;

            let ID_metarspeci = "";

            try {
                const config = {
                    mode: "cors",
                    withCredentials: false,
                    params: {
                        type_name: "MetarSpeci",
                        station_id: this.t_station.station_id,
                        _sort_des: "data_timestamp",
                        _size: 1,
                        _metadata: "id,station_id,data_timestamp,path",
                    },
                    headers: Helper.getHeader(),
                };
                const cor_url = Helper.getBaseUrl() + "@search";
                const { data } = await axios.get(cor_url, config);
                ID_metarspeci = data.items[0].id;
                this.tanggal = ID_metarspeci.substr(-15, 10);
                this.jam = ID_metarspeci.substr(-4, 2);
                this.menit = ID_metarspeci.substr(-2, 2);
            } catch (err) {
                console.log(err.response);
                this.general_data.status.COR = false;
                this.$refs["general_ref"].general_data = this.general_data;
                this.showLoading = false;
                this.donePreview = false;
                return false;
            }

            const get_url = Helper.getBaseUrl() + this.t_path + "/" + ID_metarspeci;

            try {
                const { data } = await axios.get(get_url, Helper.getConfig());
                data.m_cor = true;
                this.popupData(data);
                this.showLoading = false;
                this.donePreview = false;
            } catch (err) {
                console.log(err.response);
                this.general_data.status.COR = false;
                this.$refs["general_ref"].general_data = this.general_data;
                this.showLoading = false;
                this.donePreview = false;
            }
        },
        padZero(val, n) {
            return `${"0".repeat(n)}${val}`.substr(-n, n);
        },
        floorTo(val, n) {
            return Math.floor(val / n) * n;
        },
        roundTo(val, n) {
            return Math.round(val / n) * n;
        },
        encodeSandi() {
            if (!this.validateForm()) return false;

            let result = [];

            // METAR atau SPECI
            result.push(this.metar_speci_type);

            // COR
            if (this.general_data.status.COR) {
                result.push("COR");
            }

            // ICAO ID
            result.push(this.icao_id.toUpperCase());

            // datetime (YYGGggZ)
            const hari = this.tanggal.substr(-2, 2);
            const utc_time = hari + this.jam + this.menit + "Z";
            result.push(utc_time);

            // NIL / data kosong
            if (this.general_data.status.NIL) {
                result.push("NIL");
                this.setSandi(result);
                return true;
            }

            // AUTO
            if (this.general_data.status.AUTO) {
                result.push("AUTO");
            }

            // winds (dddffGfmfmKT)
            // console.log(VRB);
            let speed = this.general_data.winds.speed;
            let ddd = this.general_data.winds.direction;
            let VRB = this.general_data.winds.VRB;

            // winds (dndndnVdxdxdx)
            let dn = this.general_data.winds.wd_dn;
            let dx = this.general_data.winds.wd_dx;
            // console.log("DDD NYA : ",ddd)
            if (speed !== "" && (ddd !== "" || VRB)) {
                if (VRB && (ddd == "" || ddd === null)) {
                    ddd = "VRB";
                    // console.log("VERB NYA 1 : ",VRB)
                } else {
                    // console.log("VERB NYA 2 : ",VRB)
                    ddd = this.padZero(ddd, 3);
                }
                let ff = this.padZero(speed, 2);
                if (speed < 1) {
                    ddd = "000";
                    ff = "00";
                } else if (speed >= 100) {
                    ff = "P99";
                }

                let gust = this.general_data.winds.gust;
                let fmfm = "";
                if (gust) {
                    fmfm = "G" + this.padZero(gust, 2);
                }

                // result.push(ddd + ff + fmfm + "KT");

                /**
                 * added by hiskia
                 */
                let diffmore180 = false;
                if (dn !== null && dx !== null && dn !== "" && dx !== "") {
                    let dif = dx < dn ? 360 - dn + dx : dx - dn;
                    if (!(dif >= 60 && dif <= 180)) {
                        diffmore180 = true;
                        result.push("VRB" + ff + "KT");
                    }
                }

                if (!diffmore180) {
                    result.push(ddd + ff + fmfm + "KT");
                }
            }

            // winds (dndndnVdxdxdx)
            // let dn = this.general_data.winds.wd_dn;
            // let dx = this.general_data.winds.wd_dx;
            if (dn !== null && dx !== null && dn !== "" && dx !== "") {
                //** edit by Hiskia 02-09-2024
                let dif = dx < dn ? 360 - dn + dx : dx - dn;
                if (dif >= 60 && dif <= 180) {
                    let mdn = this.padZero(dn, 3);
                    let mdx = this.padZero(dx, 3);
                    // console.log("dndndnVdxdxdx", mdn, "V", mdx);
                    // result.push(dn + "V" + dx);
                    result.push(mdn + "V" + mdx);
                }

                //** before 02-09-2024
                // let mdn = this.padZero(dn, 3);
                // let mdx = this.padZero(dx, 3);
                // // console.log("dndndnVdxdxdx", mdn, "V", mdx);
                // // result.push(dn + "V" + dx);
                // result.push(mdn + "V" + mdx);
            }

            // visibility
            let prevailing = this.general_data.visibility.prevailing;
            if (prevailing) {
                if (prevailing <= 800) {
                    prevailing = this.floorTo(prevailing, 50);
                } else if (prevailing <= 5000) {
                    prevailing = this.floorTo(prevailing, 100);
                } else if (prevailing < 10000) {
                    prevailing = this.floorTo(prevailing, 1000);
                } else {
                    // lebih dari 9999
                    prevailing = 9999;
                }

                let vvvv = this.padZero(prevailing, 4);
                if (this.general_data.status.CAVOK) {
                    vvvv = "CAVOK";
                } else if (this.general_data.visibility.NDV) {
                    vvvv += "NDV";
                }
                result.push(vvvv);

                // minimum and direction (VnVnVnVnDv)
                let minimum = this.general_data.visibility.minimum;
                if (minimum) {
                    let vn = this.padZero(minimum, 4);
                    let dir = this.general_data.visibility.min_vis;
                    result.push(vn + dir);
                }
            }

            // RVR
            let rvr = this.general_data.rvr.items;
            rvr.forEach((item) => {
                if (item.vrvrvrvr || item.tendency || item.ins_limit) {
                    // vrvrvrvr terisi
                    let vrvrvrvr = item.vrvrvrvr;

                    if (vrvrvrvr <= 50) {
                        vrvrvrvr = "M0050";
                    } else if (vrvrvrvr >= 2000) {
                        vrvrvrvr = "P2000";
                    } else {
                        if (vrvrvrvr <= 400) {
                            vrvrvrvr = this.floorTo(vrvrvrvr, 25);
                        } else if (vrvrvrvr <= 800) {
                            vrvrvrvr = this.floorTo(vrvrvrvr, 50);
                        } else {
                            vrvrvrvr = this.floorTo(vrvrvrvr, 100);
                        }
                        vrvrvrvr = this.padZero(vrvrvrvr, 4);
                    }
                    let i = item.tendency || item.ins_limit;

                    result.push(item.runway + "/" + vrvrvrvr + i);
                } else {
                    // // min max terisi
                    // let vr_vis_min = item.vr_vis_min;
                    // let vr_vis_max = item.vr_vis_max;
                    // let vr_min_eks = "";
                    // let vr_max_eks = "";
                    // if (vr_vis_min <= 50) {
                    //   vr_min_eks = "M";
                    // }
                    // if (vr_vis_max >= 2000) {
                    //   vr_max_eks = "P";
                    // }
                    // vr_vis_min = vr_min_eks + this.padZero(vr_vis_min, 4);
                    // vr_vis_max = vr_max_eks + this.padZero(vr_vis_max, 4);
                    // result.push(item.runway + "/" + vr_vis_min + "V" + vr_vis_max);
                }
            });

            // weathers
            Object.values(this.general_data.weathers).forEach((item) => {
                if (item) {
                    result.push(item);
                }
            });

            // clouds
            let clear = this.general_data.clouds.CLEAR;

            if (clear !== "") {
                // jika NSC atau NCD
                result.push(clear);
            } else if (this.general_data.clouds.vertical_vis) {
                // jika vertical visibility
                let vertical_vis = this.general_data.clouds.vertical_vis;
                vertical_vis = this.floorTo(vertical_vis, 100);
                vertical_vis = this.padZero(vertical_vis / 100, 3);

                result.push("VV" + vertical_vis);
            } else {
                // jika ada clouds
                let clouds = this.general_data.clouds.items;
                let lvl_cloud = 1;
                let lapisan_cloud_old = 1;
                let lapisan_cloud_new = 1;
                let lapisan_cloud_cur = 1;
                let tinggi_cloud = {
                    FEW: 1,
                    SCT: 2,
                    BKN: 3,
                    OVC: 4,
                };
                // let cek_cloud = clouds.sort((a, b) => a.tinggi - b.tinggi);
                // console.log("cek_cloud",cek_cloud);
                clouds
                    .sort((a, b) => a.tinggi - b.tinggi)
                    .filter((e, i) => i < 4)
                    .forEach((item) => {
                        let tinggi = item.tinggi;
                        if (tinggi <= 10000) {
                            tinggi = this.floorTo(tinggi, 100);
                        } else {
                            tinggi = this.floorTo(tinggi, 1000);
                        }

                        tinggi = this.padZero(tinggi / 100, 3);

                        if (lvl_cloud == 1) {
                            if (item.type == "CB" || item.type == "TCU") {
                                lapisan_cloud_old = 0;
                            } else {
                                lapisan_cloud_old = 1;
                            }

                            result.push(item.jumlah + tinggi + item.type);
                            // console.log("lapisan ke 1 = ",tinggi_cloud[item.jumlah])
                        } else {
                            lapisan_cloud_new = tinggi_cloud[item.jumlah];
                            // console.log(lapisan_cloud_new +" = " + lapisan_cloud_old)
                            if (lapisan_cloud_new > lapisan_cloud_old) {
                                result.push(item.jumlah + tinggi + item.type);
                                lapisan_cloud_old++;
                                // console.log("code = ",item.jumlah + tinggi + item.type)
                            } else {
                                if (item.type == "CB" || item.type == "TCU") {
                                    // console.log("ada CB : ",item.jumlah)
                                    result.push(item.jumlah + tinggi + item.type);
                                }
                            }
                        }
                        lvl_cloud++;
                    });
                // console.log(result);
            }

            // air temp TT/TdTd
            let air_temp = this.general_data.air.air_temp;
            let dew_point = this.general_data.air.dew_point;
            if (air_temp && dew_point) {
                let TT = "";
                if (air_temp >= 0) {
                    // jika positif
                    // TT = this.padZero(Math.ceil(air_temp), 2);
                    TT = this.padZero(Math.round(air_temp), 2);
                } else {
                    // jika negatif
                    // TT = "M" + this.padZero(-Math.ceil(air_temp), 2);
                    TT = "M" + this.padZero(-Math.round(air_temp), 2);
                }

                let Td = "";
                if (dew_point >= 0) {
                    // jika positif
                    // Td = this.padZero(Math.ceil(dew_point), 2);
                    Td = this.padZero(Math.round(dew_point), 2);
                } else {
                    // jika negatif
                    // Td = "M" + this.padZero(-Math.ceil(dew_point), 2);
                    Td = "M" + this.padZero(-Math.round(dew_point), 2);
                }

                result.push(TT + "/" + Td);
            }

            // Qphph
            let pressure = this.general_data.air.pressure;
            if (pressure) {
                pressure = this.floorTo(pressure, 1);
                pressure = this.padZero(pressure, 4);
                result.push("Q" + pressure);
            }

            // recent weathers
            let recent_weathers = this.general_data.recent_weathers.some((item) => item);
            if (recent_weathers) {
                this.general_data.recent_weathers.forEach((item) => {
                    if (item) {
                        result.push("RE" + item);
                    }
                });
            }

            // wind shear
            let ws_all_runaway = this.general_data.wind_shear.ws_all_runaway;
            if (ws_all_runaway) {
                result.push("WS ALL RWY");
            } else {
                let wind_shear = this.general_data.wind_shear.items;
                if (wind_shear.length > 0) {
                    result.push("WS");
                    wind_shear.forEach((item) => {
                        result.push(item.runway);
                    });
                }
            }

            // sea WTsTs/SS'
            let sea_surface_temp = this.general_data.air.sea_surface_temp;
            let sea_state = this.general_data.air.sea_state;
            if (sea_surface_temp && sea_state) {
                let Ts = "";
                if (sea_surface_temp >= 0) {
                    // jika positif
                    Ts = this.padZero(Math.ceil(sea_surface_temp), 2);
                } else {
                    // jika negatif
                    Ts = "M" + this.padZero(-Math.ceil(sea_surface_temp), 2);
                }

                let SS = this.padZero(sea_state, 2);

                result.push("W" + Ts + "/" + SS);
            }

            // state of runway (RDrDrErCrererBrBr)
            let state = this.general_data.state.items;
            state.forEach((item) => {
                result.push(item.runway + item.state + item.contamination + item.depth + item.braking_coefficient);
            });

            // TREND
            if (this.metar_speci_type !== "SPECI") {
                // trend
                if (this.trend_data.trend) {
                    result.push(this.trend_data.trend);
                }

                if (this.trend_data.trend === "NOSIG") {
                    this.setSandi(result);
                    // return true;
                } else {
                    //if (this.trend_data.trend === "NOSIG") {
                    //  this.setSandi(result);
                    //  return true;
                    //} else if (this.trend_data.trend) {
                    //  result.push(this.trend_data.trend);
                    //}

                    // TTGGgg
                    let indicator_1 = this.trend_data.time_group.indicator_1;
                    let indicator_2 = this.trend_data.time_group.indicator_2;
                    let time_1 = this.trend_data.time_group.time_1;
                    let time_2 = this.trend_data.time_group.time_2;

                    if (indicator_1 && time_1) {
                        result.push(indicator_1 + time_1);
                    }
                    if (indicator_2 && time_2) {
                        result.push(indicator_2 + time_2);
                    }

                    // dddffGfmfmKT
                    let speed = this.trend_data.winds.speed;
                    let ddd = this.trend_data.winds.direction;
                    // console.log("speed :", speed);
                    // console.log("ddd : ", ddd);
                    if (speed !== "" && ddd !== "") {
                        if (speed !== null && ddd !== null) {
                            ddd = this.padZero(ddd, 3);
                            let ff = this.padZero(speed, 2);
                            if (speed < 1) {
                                ddd = "000";
                                ff = "00";
                            } else if (speed >= 100) {
                                ff = "P99";
                            }

                            let gust = this.trend_data.winds.gust;
                            let fmfm = "";
                            if (gust) {
                                fmfm = "G" + this.padZero(gust, 2);
                            }

                            result.push(ddd + ff + fmfm + "KT");
                        }
                    }

                    // visibility
                    let prevailing = this.trend_data.prevailing;
                    if (prevailing) {
                        if (prevailing <= 800) {
                            prevailing = this.floorTo(prevailing, 50);
                        } else if (prevailing <= 5000) {
                            prevailing = this.floorTo(prevailing, 100);
                        } else if (prevailing < 10000) {
                            prevailing = this.floorTo(prevailing, 1000);
                        } else {
                            // lebih dari 9999
                            prevailing = 9999;
                        }

                        let vvvv = this.padZero(prevailing, 4);
                        if (this.trend_data.status.CAVOK) {
                            vvvv = "CAVOK";
                        }
                        result.push(vvvv);
                    }

                    // weather
                    let forecast_weather = this.trend_data.forecast_weather;
                    if (this.trend_data.weather.NSW) {
                        result.push("NSW");
                    } else if (forecast_weather) {
                        result.push(forecast_weather);
                    }

                    // clouds
                    if (this.trend_data.weather.NSC) {
                        result.push("NSC");
                    } else if (this.trend_data.clouds.vertical_vis) {
                        // jika vertical visibility
                        let vertical_vis = this.trend_data.clouds.vertical_vis;
                        vertical_vis = this.floorTo(vertical_vis, 100);
                        vertical_vis = this.padZero(vertical_vis / 100, 3);

                        result.push("VV" + vertical_vis);
                    } else {
                        // jika ada clouds
                        let clouds = this.trend_data.clouds.items;
                        let lvl_cloud = 1;
                        let lapisan_cloud_old = 1;
                        let lapisan_cloud_new = 1;
                        let tinggi_cloud = {
                            FEW: 1,
                            SCT: 2,
                            BKN: 3,
                            OVC: 4,
                        };
                        clouds
                            .sort((a, b) => a.tinggi - b.tinggi)
                            .filter((e, i) => i < 3)
                            .forEach((item) => {
                                let tinggi = item.tinggi;
                                if (tinggi <= 10000) {
                                    tinggi = this.floorTo(tinggi, 100);
                                } else {
                                    tinggi = this.floorTo(tinggi, 1000);
                                }
                                tinggi = this.padZero(tinggi / 100, 3);
                                if (lvl_cloud == 1) {
                                    if (item.type == "CB" || item.type == "TCU") {
                                        lapisan_cloud_old = 0;
                                    } else {
                                        lapisan_cloud_old = 1;
                                    }

                                    result.push(item.jumlah + tinggi + item.type);
                                    // console.log("lapisan ke 1 = ",tinggi_cloud[item.jumlah])
                                } else {
                                    lapisan_cloud_new = tinggi_cloud[item.jumlah];
                                    // console.log(lapisan_cloud_new +" = " + lapisan_cloud_old)
                                    if (lapisan_cloud_new > lapisan_cloud_old) {
                                        result.push(item.jumlah + tinggi + item.type);
                                        lapisan_cloud_old++;
                                        // console.log("code = ",item.jumlah + tinggi + item.type)
                                    } else {
                                        if (item.type == "CB" || item.type == "TCU") {
                                            // console.log("ada CB : ",item.jumlah)
                                            result.push(item.jumlah + tinggi + item.type);
                                        }
                                    }
                                }
                                lvl_cloud++;
                            });
                    }
                }
            }

            // REMARK
            if (this.remark.trim()) result.push("RMK " + this.remark.trim());

            // hasil encode sandi
            this.setSandi(result);
        },
        setSandi(result) {
            let dataid = this.tanggal.split("-");

            let header = this.metar_speci_type !== "SPECI" ? this.metarspeci_gts_metar_header : this.metarspeci_gts_speci_header;
            header = header && header != "" ? header + " " + dataid[2] + this.jam + this.menit + "\n" : "";

            this.sandi = result.join(" ") + "=";
            this.sandi_with_header = header + result.join(" ") + "=";
            this.donePreview = true;
            // console.log("result setSandi: ",header, this.metar_speci_type, this.metarspeci_gts_metar_header, this.metarspeci_gts_speci_header);
        },

        sendGtsMessage() {
            let header = this.metar_speci_type !== "SPECI" ? this.metarspeci_gts_metar_header : this.metarspeci_gts_speci_header;

            if (header && header != "") {
                this.sandi_gts_message = this.sandi_with_header;
                this.$refs["send-gts-message"].show();
            } else {
                let title = "";
                let message = "";
                if (this.is_metarspeci_send_to_bmkgswitching == 0) {
                    title = "Anda tidak memiliki ijin untuk mengirim ke GTS Switching";
                    message = "Periksa metadata stasiun atau hubungi admin anda.";
                } else {
                    title = "Header Tidak Ada / Tidak Ditemukan";
                    message = "Header belum ada pada metadata.";
                }
                this.$swal({
                    title: title,
                    text: message,
                    icon: "danger",
                    customClass: {
                        confirmButton: "btn btn-danger",
                    },
                    buttonsStyling: false,
                });
                return;
            }
        },

        onCancel() {
            this.$refs["send-gts-message"].hide();
        },

        onSendBmkgSwitching() {
            this.submitSendBmkgSwitching();
        },

        async submitSendBmkgSwitching() {
            // this.tanggal.substr(-2, 2);
            // let GG = this.jam.substr(0, 2);
            // let id = "1" + this.t_wmoid + this.tanggal.toString().replace(/-/g,'') + this.jam.toString().replace(/:/g,'')
            let noId = this.metar_speci_type !== "SPECI" ? 4 : 5;

            let mdate = new Date();
            let id =
                "" +
                this.padZero(noId, 2) +
                this.t_wmoid +
                mdate.getFullYear() +
                this.padZero(mdate.getMonth() + 1, 2) +
                this.padZero(mdate.getDate(), 2) +
                this.padZero(mdate.getHours(), 2) +
                this.padZero(mdate.getMinutes(), 2) +
                this.padZero(mdate.getSeconds(), 2);
            // console.log("submitSendBmkgSwitching - id", id, this.t_station)

            let tmp_sandi = this.sandi_gts_message.split(/[,\s]+/);
            const post_data = {
                "@type": "GTSMessage",
                id: id,
                type_message: noId,
                sandi_gts: this.sandi_gts_message,
                // "timestamp_data": this.tanggal + "T" + this.jam,
                timestamp_sent_data:
                    mdate.getUTCFullYear() +
                    "-" +
                    this.padZero(mdate.getUTCMonth() + 1, 2) +
                    "-" +
                    this.padZero(mdate.getUTCDate(), 2) +
                    "T" +
                    this.padZero(mdate.getUTCHours(), 2) +
                    ":" +
                    this.padZero(mdate.getUTCMinutes(), 2) +
                    ":" +
                    this.padZero(mdate.getUTCSeconds(), 2),
                timestamp_data: this.tanggal + "T" + this.jam + this.menit,
                wmoid: this.t_wmoid,
                ttaaii: tmp_sandi.length > 0 ? tmp_sandi[0] : "",
                cccc: tmp_sandi.length > 1 ? tmp_sandi[1] : "",
            };

            let url_gts = Helper.getBaseUrl() + this.t_station.path + "/gts";
            // console.log('post_data', post_data, url_gts)
            this.showLoading = true;
            await axios
                .post(url_gts, post_data, Helper.getConfig())
                .then((response) => {
                    if (response.status == 201 || response.status == 200 || response.status == 204) {
                        this.$swal({
                            title: "Input Data GTS Messages Berhasil",
                            text: "Input Data GTS Message",
                            icon: "success",
                            customClass: {
                                confirmButton: "btn btn-primary",
                            },
                            buttonsStyling: false,
                        });
                        this.showLoading = false;
                        this.$refs["send-gts-message"].hide();
                    }
                    this.showLoading = false;
                })
                .catch((error) => {
                    this.showLoading = false;
                    // error_handler(this, error, "Sinoptik", "Input Data Error");
                    // console.log('error', error, error.response.status, )
                    if (error.response.status == 409) {
                        // this.updateFormComfirm(id, post_data)
                    } else {
                    }
                });

            // this.clearData()
            // this.showLoading = false
        },
    },
    watch: {
        tanggal() {
            this.fetchData();
        },
        jam() {
            this.fetchData();
        },
        menit() {
            this.fetchData();
        },
        t_wmoid: async function (val) {
            this.t_observer = null;
            this.icao_id = "";
            // this.metar_speci_type = "METAR";

            if (val) {
                this.t_station = this.t_station_list[val];
                // console.log("stasiun : ",this.t_station);
                this.icao_id = this.t_station.icao;
                const { data } = await metadata.getPath("MetarSpeciCollect", this.t_station.station_id, this.t_station.path);
                this.t_path = data[0].path;

                await this.listObserver();
                await this.setQCData();
                await this.getOprHours();
                this.fetchData();
            } else {
                this.t_station = "";
                this.t_path = "";
                this.icao_id = "";
            }
        },
        metar_speci_type: function (val) {
            if (val == "SPECI") {
                this.tabDisabled = true;
                this.options.menit = [
                    "00",
                    "01",
                    "02",
                    "03",
                    "04",
                    "05",
                    "06",
                    "07",
                    "08",
                    "09",
                    "10",
                    "11",
                    "12",
                    "13",
                    "14",
                    "15",
                    "16",
                    "17",
                    "18",
                    "19",
                    "20",
                    "21",
                    "22",
                    "23",
                    "24",
                    "25",
                    "26",
                    "27",
                    "28",
                    "29",
                    "30",
                    "31",
                    "32",
                    "33",
                    "34",
                    "35",
                    "36",
                    "37",
                    "38",
                    "39",
                    "40",
                    "41",
                    "42",
                    "43",
                    "44",
                    "45",
                    "46",
                    "47",
                    "48",
                    "49",
                    "50",
                    "51",
                    "52",
                    "53",
                    "54",
                    "55",
                    "56",
                    "57",
                    "58",
                    "59",
                ];
            } else {
                this.tabDisabled = false;
                this.options.menit = this.options.menit_hourly;
            }
        },
        "general_data.status.COR": function (after) {
            if (this.isEdit) return false;
            if (after) {
                if (this.t_station === "") {
                    this.$toast({
                        component: ToastificationContent,
                        props: {
                            title: "Pilih stasiun terlebih dahulu",
                            icon: "BellIcon",
                            variant: "danger",
                        },
                    });
                    this.general_data.status.COR = false;
                    this.$refs["general_ref"].general_data = this.general_data;
                    this.showLoading = false;
                    return false;
                }
                this.getCorData();
            } else {
                this.tanggal = "";
                this.jam = "";
                this.menit = "";
                this.donePreview = false;
                this.resetForm();
            }
        },
    },
};
</script>

<style scoped>
.custom-title {
    display: flex;
    align-items: center;
    justify-content: flex-end;
}

.label-entry {
    text-align: center;
    /* color: white; */
    width: 100%;
    /* font-weight: bold !important; */
    /* border: 1px solid #858585;; */
    /* border-radius: 3px; */
    /* background: #858585; */
    padding: 4px;
}
</style>

<style>
.hidden_header {
    display: none;
}
.pad2 {
    padding-left: 2px;
    padding-right: 2px;
}
</style>
